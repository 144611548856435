.formCard {
  width: 100%;
  max-width: 540px;
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.formCardContent {
  width: 100%;
  max-width: 360px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.heading {
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
}

.text {
  font-size: 12px;
  color: black;
  margin-bottom: 0;
}

.link {
  color: black;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    color: black;
    text-decoration: none;
  }
}

.button {
  width: 100%;
  background-color: #3b82f6;
  color: white;
  padding: 0.75rem 1rem;
  border-radius: 0.375rem;
  transition: background-color 0.2s;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #2563eb;
  }
}
