.internalId {
  font-size: 12px;
  font-weight: bold;
  color: black;
  min-width: 120px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.tagContainer {
  display: flex;
  gap: 4px;
}

.tag {
  font-size: 9px;
  padding: 4px;
  border-radius: 4px;
  font-weight: bold;
  width: max-content;
}

.danger {
  background-color: #f6cdcd;
  color: #de0f0f;
}

.info {
  background-color: rgba(14, 70, 165, 0.2);
  color: #0e46a5;
}

.warning {
  background-color: rgba(255, 190, 0, 0.25);
  color: #efb121;
}
