:export {
  darkOrange: #f26522;
  darkBlueGrey: #1d3a50;
  lightGreen: #3eb456;
  lightGrey: #707070;
  greenSuccess: #3eb456;
  blueBoxShadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  blueFocusBorder: #80bdff;
  grayBorder: #ced4da;
  graySecondary: #6c757d;
  red: #ff0000;
  markerBlue: #2294f9;
  markerGreen: #01e34d;
  grayText: #333333;
  blue1: #2e75d8;
  gray2: #4b4a4a;
  black: #000;
}

.switcherLogic {
  visibility: hidden;
}
.switcherLogic:checked + label {
  background-color: #2e75d8;
}
.switcherLogic:checked + label:before {
  transform: translateX(12px);
  background-color: #fff;
}
.switcherLogic:checked + label.green {
  background-color: #3eb456;
  border-color: #3eb456;
}
.switcherLogic:checked + label.green:before {
  transform: translateX(12px);
  background-color: #fff;
}

.switcher {
  display: flex;
  width: 30px;
  height: 16px;
  border: 1px solid #2e75d8;
  border-radius: 99em;
  position: relative;
  transition: transform 0.35s ease-in-out;
  transform-origin: 50% 50%;
  cursor: pointer;
  margin: 0;
  background-color: #fff;
}
.switcher.green {
  border-color: #3eb456;
}
.switcher.green:before {
  background-color: #3eb456;
}
.switcher:before {
  transition: transform 0.35s ease;
  content: "";
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: #2e75d8;
  border-radius: 50%;
  top: 1px;
  left: 2px;
}
.switcher:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.switcher:focus.green {
  box-shadow: 0 0 0 0.2rem rgba(62, 180, 86, 0.25);
}