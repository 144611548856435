@import '../../../../../assets/stylesheets/colors.scss';

.switcherLogic {
  visibility: hidden;

  &:checked + label {
    background-color: $blue-1;

    &:before {
      transform: translateX(12px);
      background-color: $white;
    }
  }

  &:checked + label.green {
    background-color: $green-1;
    border-color: $green-1;

    &:before {
      transform: translateX(12px);
      background-color: $white;
    }
  }
}

.switcher {
  display: flex;
  width: 30px;
  height: 16px;
  border: 1px solid $blue-1;
  border-radius: 99em;
  position: relative;
  transition: transform 0.35s ease-in-out;
  transform-origin: 50% 50%;
  cursor: pointer;
  margin: 0;
  background-color: $white;

  &.green {
    border-color: $green-1;

    &:before {
      background-color: $green-1;
    }
  }

  &:before {
    transition: transform 0.35s ease;
    content: '';
    display: block;
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: $blue-1;
    border-radius: 50%;
    top: 1px;
    left: 2px;
  }

  &:focus {
    box-shadow: $blue-box-shadow;

    &.green {
      box-shadow: $green-box-shadow;
    }
  }
}
