@import '../../../../assets/stylesheets/colors.scss';

.msgToDisplay {
  margin-bottom: 1rem;
}

.alertsSaveButton {
  width: 100%;
  max-width: 150px;

  background-color: $green-1;
  color: #fff;
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: $green-1-dark;
  }
}
