.inputContainer {
  display: flex;
  align-items: center;
  border: 1px solid #b7b7b7;
  border-radius: 6px;
  padding: 0.5rem 1rem;
  gap: 0.5rem;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inputLabel {
  font-size: 0.75rem;
  color: #6b7280;
  margin-bottom: 0;
}

.iconWrapper {
  display: inline-flex;
  font-size: 2rem;
}

.phoneInput {
  font-size: 1rem;
  outline: none;
  border: none;
}

.invalid {
  border: 1px solid red;
}

.errorMessage {
  font-size: 1rem;
  color: red;
  margin-top: 0.25rem;
  margin-bottom: 0;
}
