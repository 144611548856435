.alertSetting {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 3rem;
}

.title {
  font-size: 1.25rem;
  font-weight: 400;
}

.multiSelectContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.switcherContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
}